<template>
    <v-dialog
        v-model="dialogForm"
        scrollable
        persistent
        content-class="dialog-ecommerce br-15"
    >
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title>
                    {{ message }} - Etapa ({{ etapa }} de 5)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    color="#fff"
                    @click="fecharDialogo()"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-stepper v-model="etapa" non-linear class="elevation-0 card-body">
                <v-stepper-header class="px-2">
                    <v-stepper-step
                        editable
                        :rules="regra.etapa1"
                        error-icon="mdi-alert-circle"
                        step="1"
                    >
                        Cadastro de acordo
                    </v-stepper-step>
                    <v-divider class="mx-2" />
                    <v-stepper-step
                        editable
                        :rules="regra.etapa2"
                        error-icon="mdi-alert-circle"
                        step="2"
                    >
                        Seleção de Filiais
                    </v-stepper-step>
                    <v-divider class="mx-2" />
                    <v-stepper-step
                        editable
                        :rules="regra.etapa3"
                        error-icon="mdi-alert-circle"
                        step="3"
                    >
                        Valor
                    </v-stepper-step>
                    <v-divider class="mx-2" />
                    <v-stepper-step
                        editable
                        :rules="regra.etapa4"
                        error-icon="mdi-alert-circle"
                        step="4"
                    >
                        Seleção de Produtos</v-stepper-step
                    >
                    <v-divider class="mx-2" />
                    <v-stepper-step editable step="5"
                        >Efetivação do acordo</v-stepper-step
                    >
                </v-stepper-header>

                <div class="etapas-conteudo">
                    <v-stepper-items class="min-h-100">
                        <v-stepper-content step="1">
                            <Cadastro
                                ref="cadastro"
                                v-model="acordo"
                                :usuarioTipo="usuarioTipo"
                                @limpaFiliais="$refs.filial.limpaSelecionadas()"
                            ></Cadastro>
                        </v-stepper-content>
                        <v-stepper-content class="min-h-100" step="2">
                            <filial
                                ref="filial"
                                @limpaDescontos="
                                    $refs.desconto.limpaDescontos()
                                "
                                v-model="acordo"
                            ></filial>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="min-h-100">
                            <Valores ref="desconto" v-model="acordo"></Valores>
                        </v-stepper-content>
                        <v-stepper-content step="4" class="min-h-100">
                            <Produto ref="produto" v-model="acordo"></Produto>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                            <Efetivacao ref="efetivacao" v-model="acordo" />
                        </v-stepper-content>
                    </v-stepper-items>
                </div>
                <div class="d-flex card-footer pa-4">
                    <v-btn
                        color="yellow"
                        width="130px"
                        class="new-button"
                        dark
                        @click="voltar"
                    >
                        <v-icon left>mdi-chevron-left-circle</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        width="130px"
                        class="new-button"
                        @click="proximo"
                        v-if="!acordo.fnLeitura || etapa < 5"
                    >
                        {{ botao }}
                        <v-icon right>{{ botaoIco }}</v-icon>
                    </v-btn>
                </div>
            </v-stepper>
        
        </v-card>
        <!-- <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar> -->
        <v-overlay z-index="500" :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import Cadastro from "./Cadastro.vue";
import Efetivacao from "./Efetivacao.vue";
import Valores from "./Valores.vue";
import Produto from "./Produto.vue";
import Filial from "./Filial.vue";
import { arrayMax } from "highcharts";
export default {
    name: 'formularioCampanha',
    inject: ["listaCampos"],
    components: {
        Cadastro,
        Efetivacao,
        Valores,
        Produto,
        Filial,
    },
    data() {
        return {
            etapa: 1,
            lista: [],
            message: "Criação de acordo",
            dialogForm: false,
            carregando: false,
            acordo: {
                desconto: [],
                periodo: [],
                // mesInicial: [], // remover depois
                // mesFinal: this.$moment().format("YYYY-MM"), // remover depois
                clustersNoAcordo: [],
            },
            usuarioTipo: 'rd',
            botao: "Avançar",
            botaoIco: "mdi-chevron-right-circle",
            clusters: ['POPULAR','SUPER POPULAR', 'HIBRIDA', 'NOBRE', 'SUPER NOBRE', 'AMPLA'],
            regra: {
                etapa1: [
                    () => {
                        if (this.etapa > 1) {
                            return this.$refs.cadastro.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa2: [
                    () => {
                        if (this.etapa > 2) {
                            return this.$refs.filial.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa3: [
                    () => {
                        if (this.etapa > 3) {
                            return this.$refs.desconto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa4: [
                    () => {
                        if (this.etapa > 4) {
                            return this.$refs.produto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa5: [
                    () => {
                        if (this.etapa > 5) {
                            return this.$refs.efetivacao.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
            },
        };
    },
    async provide() {
        return {
            listaCampos: await this.listaCampos,
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.cluster = lista.cluster
    },
    watch: {
        etapa(valor) {
            if (this.acordo.preAcordo == true && value === 3) {
                this.botao = "Salvar";
                this.botaoIco = "mdi-content-save";
                return;
            }
            if (valor == 1) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
            }
            if (valor == 2) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";

                // this.acordo = { ...this.acordo, ...this.$refs.cadastro.acordo };

                this.carregaFilial();
                // this.$refs.produto.setAcordo(this.acordo);
                // this.$refs.planograma.setAcordo(this.acordo);

                // if (this.$refs.filial.dados.length === 0) {
                //     this.$refs.filial.filtrar(true, true);
                // }
            } else if (valor == 3) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
                // const errosFiliais = !this.$refs.filial.validate();
                this.carregaValores();

                // this.$refs.produto.buscaProdutos();
            } else if (valor == 4) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
                this.carregaProduto();
            } else if (valor == 5) {
                // this.carregaPlanograma();
                this.botao = "Salvar";
                this.botaoIco = "mdi-check-circle";
                this.resumoAcordo();
            }
            this.disabledVoltar = this.passo == 1 ? true : false;
        },
    },
    methods: {
        async carregaFilial() {
            await this.$refs.filial.setAcordo();
        },
        async carregaProduto() {
            console.log(280,this.acordo.clustersNoAcordo,this.lista.cluster)
            let clusterNomes = []

            for (var a of this.acordo.clustersNoAcordo) {
                for (var b of this.lista.cluster) {
                    if (a == b.value) {
                        clusterNomes.push({id:a,nome:b.text})
                    }
                }
            }
            console.log('...campanha',clusterNomes)
            if (this.acordo.clustersNoAcordo[0] == 7) {
                this.acordo.clustersNomes = this.clusters
            } else {
                this.acordo.clustersNomes = clusterNomes
            }

                        
            this.acordo.produtos_por_cluster = [4491,633,669,645,681,657,687].includes(this.acordo.id_espaco_pdv)
            console.log('clusterNomesx', this.acordo.clustersNomes,this.acordo.produtos_por_cluster)
            await this.$refs.produto.buscaProdutos(); 
        },
        filiaisEscolhidas (filiais) {
            const periodo = this.$refs.filial.getPeriodo()
            console.log(periodo)
            filiais = filiais.filter(item => {
                let elegivel = false
                for (var i of periodo) {
                    if (item[i]) {
                        elegivel = true
                    } 
                }
                return elegivel
            }) 
            console.log(filiais)
            return filiais
        },  
        resumoAcordo() {
            const totalInvestimento = this.acordo.valores.reduce((acc, cur) => {
                return acc + +cur.investimento;
            }, 0);
            const resumo = {
                filiais: this.filiaisEscolhidas(this.$refs.filial.filiais).length,
                investimento: totalInvestimento,
                produtos: this.$refs.produto.produtoSalvo.length,
            };
            this.$refs.efetivacao.setResumo(resumo);
        },
        getPeriodo(campo = "quantidade") {
            let extraido =  []
            for (var i of this.acordo.periodo) {
	            extraido.push(campo + '_' + i.substring(0, 4) + '_' + parseInt(i.substring(5, 7)))
            }
            return extraido;
        },
        async salvarAcordo() {
            if (
                !this.$refs.cadastro.validate() ||
                !this.$refs.desconto.validate() ||
                !this.$refs.filial.validate() ||
                !this.$refs.efetivacao.validate() ||
                !this.$refs.produto.validate()
            ) {
                return this.$dialog.notify.error("Formulário com erros", {
                    position: "top-right",
                    timeout: 5000,
                });
            }
            this.carregando = true;
            const data = this.$helpers.cloneObj(this.acordo);

            const periodo = this.getPeriodo()
            const periodoDisponivel = this.getPeriodo('disponivel')

            // console.log('getPeriodo',periodo)

            data.filiais = this.$refs.filial.filiais.map((a) => {
                let obj = {
                    id_filial: a.id_filial,
                    id_java: a.id_java,
                    custo: a.custo,

                };

                for (const e of periodo) {
                    obj[e] = a[e];
                }

                for (const e of periodoDisponivel) {
                    obj[e] = a[e];
                }

                return { ...obj };
            });


            if ((data.produtos_por_cluster)) {
                // console.log('aqui',this.clusters,this.$refs.produto.produtoSalvo)
                data.produto = this.$refs.produto.produtoSalvo.map((a) => {
                    var item = {id_produto: a.id_produto, ecommerce: a.ecommerce}
                    for (const cluster of data.clustersNomes) {
                        item[cluster.nome] = a[cluster.nome]
                    }
                    return item
                });
                // console.log('passou')
                // data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
                //     id_produto: a.id_produto,
                //     vb: a.vb,
                // }));
            } else {
                data.produto = this.$refs.produto.produtoSalvo.map((a) => ({
                    id_produto: a.id_produto,
                    vb_pdv: a.vb_pdv,
                    pdv: a.pdv,
                    ecommerce: a.ecommerce,
                }));
            }


            // console.log(data)

            // process.exit()

            const formData = new FormData();
            // formData.append("files", this.acordo.planograma);
            formData.append("data", JSON.stringify(data));
            let response;
            if (data.fnEditar) {
                response = await this.$http().post(
                    "/acordoCampanhas/editar",
                    formData
                );
            } else {
                response = await this.$http().post(
                    "/acordoCampanhas/salvar",
                    formData
                );
            }
            if (response.data.sucesso) {
                this.$dialog.notify.success(" Acordo criado com sucesso!", {
                    position: "top-right",
                    timeout: 5000,
                });
                this.dialogForm = false;
                this.$emit("filtrar");
                this.carregando = false;
            } else {
                this.$dialog.notify.error(
                    response.data?.mensagem || "Erro ao salvar acordo",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.carregando = false;
            }

            return;
        },
        proximo() {
            if (this.etapa == 5) {
                return this.salvarAcordo();
            } else {
                this.etapa++;
            }
        },
        voltar() {
            if (this.etapa > 1) {
                this.etapa--;
            }
        },
        fecharDialogo() {
            this.etapa = 1;
            this.dialogForm = false;
        },
        async carregaValores() {
            await this.$refs.desconto.carregaValores();
        },
        novo(usuarioTipo) {
            this.usuarioTipo = usuarioTipo
            this.dialogForm = true;
            this.etapa = 1;
            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                this.$refs.desconto.limpaDescontos();
                this.$refs.produto.reset();
            } catch (error) {
                error;
            }
            (this.message = "Criação de acordo"), (this.carregando = false);
            this.acordo = {
                fnReplicar: false,
                periodo: [],
                valores: [],
                produtos: [],
                clustersNoAcordo: [],
                clustersNomes: [],
                clusters: [],
                produtos_por_cluster: false
            };
            this.$refs.cadastro.resetValidation();

        },
        async editar(item, fnLeitura = false) {
            this.dialogForm = true;
            if (fnLeitura) {
                this.message = `Exibir Acordo (${item.id_acordo_campanha}) `;
            } else {
                this.message = `Editar Acordo (${item.id_acordo_campanha}) `;
            }
            this.carregando = true;

            var data = {
                id_acordo_campanha: item.id_acordo_campanha,
            };

            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                // this.$refs.planograma.resetValidation();
                this.$refs.desconto.limpaDescontos();
                this.$refs.desconto.limpaDescontos();
            } catch (error) {
                //console.log(error)
            }

            const response = await this.$http().post(
                "/acordoCampanhas/acordo",
                {
                    data: data,
                }
            );

            const contrato = response.data.contrato;
            const acordo = response.data.acordo;
            const fornecedor = response.data.fornecedor;
            const idAcordosCampanha = response.data.idAcordosCampanha;
            const idAcordosPDV = response.data.idAcordosPDV;
            const idAcordosEcommerce = response.data.idAcordosEcommerce;
            this.status = response.data.status;
            const periodo = response.data.periodo;

            this.etapa = 1;
            this.acordo = {
                fnReplicar: false,
                fnEditar: true,
                fnLeitura: fnLeitura,
                idAcordos: idAcordosCampanha || [],
                idAcordosPDV: idAcordosPDV || [],
                idAcordosEcommerce: idAcordosEcommerce || [],
                valores: [],
                produtos: [],
                // mesInicial: item.mes_acordo,
                // mesFinal: item.termino,
                periodo: periodo,
                cota: contrato.cota,
                id_campanha: contrato.id_campanha,
                id_categoria_produto: acordo.id_categoria_produto,
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                idStatus: item.id_status,
                id_fornecedor: contrato.id_fornecedor,
                fornecedor: fornecedor.nome,
                id_espaco_pdv: contrato.id_espaco_pdv,
                clusterRede: contrato.cluster_rede,
                id_espaco_ecommerce: contrato.id_espaco_ecommerce,
                investimento: acordo.investimento,
                clustersNoAcordo: acordo.clusters_acordo,
                visivel: acordo.visivel,
                espaco_macro: acordo.espaco_macro,
                clustersNomes: []
            };
            await new Promise((resolve) => setTimeout(resolve, 100));
            await this.carregaFilial();
            await this.carregaValores();
            await this.$refs.produto.limpaProdutos(true);
            await this.carregaProduto();

            this.carregando = false;
        },
        async replicarAcordo(item, tipoReplicacao) {
            this.dialogForm = true;
  
            this.message = `Replicar Acordo (${item.id_acordo_campanha}) `;
         
            this.carregando = true;

            var data = {
                id_acordo_campanha: item.id_acordo_campanha,
            };

            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.resetValidation();
                this.$refs.filial.limpaFiltro();
                this.$refs.filial.limpaSelecionadas();
                this.$refs.produto.limpaProdutos();
                // this.$refs.planograma.resetValidation();
                this.$refs.desconto.limpaDescontos();
                this.$refs.desconto.limpaDescontos();
            } catch (error) {
                //console.log(error)
            }

            const response = await this.$http().post(
                "/acordoCampanhas/acordo",
                {
                    data: data,
                }
            );

            const contrato = response.data.contrato;
            const acordo = response.data.acordo;
            const fornecedor = response.data.fornecedor;
            const idAcordosCampanha = response.data.idAcordosCampanha;
            const idAcordosPDV = response.data.idAcordosPDV;
            const idAcordosEcommerce = response.data.idAcordosEcommerce;
            const periodo = response.data.periodo;

            this.status = response.data.status;

            this.etapa = 1;
            this.acordo = {
                fnReplicar: true,
                idAcordos: idAcordosCampanha || [],
                idAcordosPDV: idAcordosPDV || [],
                idAcordosEcommerce: idAcordosEcommerce || [],
                valores: [],
                produtos: [],
                periodo: periodo,
                cota: contrato.cota,
                id_campanha: contrato.id_campanha,
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                idStatus: item.id_status,
                id_fornecedor: contrato.id_fornecedor,
                fornecedor: fornecedor.nome,
                id_espaco_pdv: contrato.id_espaco_pdv,
                clusterRede: contrato.cluster_rede,
                id_espaco_ecommerce: contrato.id_espaco_ecommerce,
                investimento: acordo.investimento,
                clustersNoAcordo: acordo.clusters_acordo,
                visivel: acordo.visivel,
                espaco_macro: acordo.espaco_macro
            };
            await new Promise((resolve) => setTimeout(resolve, 100));
            await this.carregaFilial();
            await this.carregaValores();
            await this.$refs.produto.limpaProdutos(true);
            await this.carregaProduto();

            this.carregando = false;
        },
    },
};
</script>
<style>
.card-econ {
    height: 100%;
    background: red;
}
.etapas-conteudo {
    height: 100%;
    overflow: auto;
}
.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.card-footer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
}
.fix-flex {
    flex-grow: 0;
    flex-shrink: 1;
}
</style>
<style lang="scss">
.dialog-ecommerce {
    height: 100%;
    max-height: calc(100% - 50px) !important;
    .v-stepper__header {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    }
    .v-stepper__content {
        box-shadow: unset !important;
    }
    .v-stepper__step {
        padding: 10px;
        margin: 15px;
        background-color: #f5f5f5;
        border-radius: 8px;
        min-width: 200px;
        justify-content: center;
        .v-stepper__label {
            color: #000 !important;
            font-weight: 500;
            font-size: 0.9rem;
        }
        .v-stepper__step__step {
            background-color: var(--v-primary-base) !important;
            height: 20px;
            width: 20px;
            min-width: 20px;
        }
        &:hover .v-stepper__label {
            text-shadow: unset !important;
        }
        &.v-stepper__step--error {
            .v-stepper__label {
                color: red !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                .v-icon {
                    color: red;
                    font-size: 24px;
                }
            }
        }
        &.v-stepper__step--active {
            background-color: var(--v-primary-base);
            color: white;
            &:hover {
                background-color: var(--v-primary-base);
            }
            .v-stepper__label {
                color: #fff !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                color: var(--v-primary-base) !important;
            }
        }
    }
}
</style>